import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface IStringIndex {
  [key: string]: BehaviorSubject<[]>
}

@Injectable()
export class RouteHelperService {

  private RouteId: any = new BehaviorSubject([]);
  currentRoute: any = new BehaviorSubject([]);
  lastRoute: any = new BehaviorSubject([]);
  private routeSection: BehaviorSubject<string> = new BehaviorSubject('');
  changeRoute = this.routeSection.asObservable();

  private routePath: BehaviorSubject<string> = new BehaviorSubject('');
  changePath = this.routePath.asObservable();

  constructor() {}

  setRoute(Route: any) {
    this.RouteId.next(Route);
  }

  getCurrentRoute() {
    this.currentRoute = this.RouteId[0].asObservable();
  }

  getLastRoute() {
    console.log(this.RouteId)
    this.lastRoute = this.RouteId[1].asObservable();
    return this.lastRoute;
  }

  setSection(route: string) {
    //console.log(route)
    this.routeSection.next(route);
  }

  getSection(){
    return this.routeSection.value;
  }

  setRoutePath(route: string) {
    //console.log(route)
    this.routePath.next(route);
  }

  getRoutePath(){
    return this.routePath.value;
  }

}
