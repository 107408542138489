import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userData: any;
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}

  // Setting logged in user in localstorage else null
  setCurrentUserValue(){ 
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        console.log('setting user')
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
        this.router.navigate(['home']);  
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

   // Returns true when user is looged in and email is verified
   get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    //console.log(user)
    if (user == null) {
      return false;
    } else {
      return true;
    }
  }
   // Auth logic to run auth providers
   AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result: any) => {
        this.ngZone.run(() => {

          this.router.navigate(['home']);
        });
      })
      .catch((error: any) => {
        window.alert(error);
      });
  }
  // Sign in with email/password
  SignIn(email:string, password:string, path:string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
          //console.log(result)
          this.setCurrentUserValue()
             
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }
}