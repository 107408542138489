import { Component, OnInit } from '@angular/core';
import { EncryptionService } from './shared/services/encryption-service/encryption.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './shared/services/analytics.service';
import { RouteHelperService } from './shared/services/RouteHelper.service';
import { FirestoreService } from './shared/services/firestore-service/firestore.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public mainPages: any[] = [];
  selectedLanguage!: string;
  menuTranslations!: any;
  currentPath!: any;
  settings!:any;
  isLive!:boolean;
  randomString:string = 'lhslshlhlh';

  constructor(private router: Router,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private firestoreService: FirestoreService
  ) {
    this.analyticsService.initAnalytics();
    this.translateService.setDefaultLang('en');

    let storageLanguage = window.localStorage.getItem('language');
    if (storageLanguage) {
      this.selectedLanguage = storageLanguage;
      this.translateService.use(storageLanguage);
    } else {
      this.selectedLanguage = 'en';
      this.translateService.use('en');
    }

    this.translateService.get('sideMenu').subscribe(value => {
      this.menuTranslations = value;
    })
  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;

      this.firestoreService.getSettings('florence').then(value => {
        console.log(value)

        this.settings = value;
        this.isLive = value?.homeView?.preLaunch;
          if (this.currentPath.split('/')[1] === 'game') {
            this.mainPages = [
              { title: this.menuTranslations.home, url: '/game/home', disabled:false },
              { title: this.menuTranslations.results, url: '/game/path/florence', disabled: this.isLive },
              { title: this.menuTranslations.terms, url: '/terms', disabled:false  },
              { title: this.menuTranslations.cookies, url: '/terms/cookies', disabled:false  }
            ];
          } else {
            this.mainPages = [
              { title: this.menuTranslations.home, url: '/home', disabled:false  },
              { title: this.menuTranslations.game, url: '/game/home', disabled: this.isLive },
              { title: this.menuTranslations.terms, url: '/terms', disabled:false  },
              { title: this.menuTranslations.cookies, url: '/terms/cookies', disabled:false }
            ]
          }

        

      })
    })
  }

  changeLanguage() {
    this.translateService.use(this.selectedLanguage);
    this.translateService.get('sideMenu').subscribe(value => {
      this.menuTranslations = value;
      console.log(value)
      if (this.currentPath.split('/')[1] === 'game') {
        this.mainPages = [
          { title: this.menuTranslations.home, url: '/game/home', disabled:false  },
          { title: this.menuTranslations.results, url: '/game/path/florence', disabled: this.isLive },
          { title: this.menuTranslations.terms, url: '/terms', disabled:false  },
          { title: this.menuTranslations.cookies, url: '/terms/cookies', disabled:false  }
        ];
      } else {
        this.mainPages = [
          { title: this.menuTranslations.home, url: '/home', disabled:false  },
          { title: this.menuTranslations.game, url: '/game/home', disabled: this.isLive },
          { title: this.menuTranslations.terms, url: '/terms', disabled:false  },
          { title: this.menuTranslations.cookies, url: '/terms/cookies', disabled:false }
        ]
      }
    })
    window.localStorage.setItem('language', this.selectedLanguage);
  }
}
