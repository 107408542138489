import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  gtagProperty:string = environment.gaProperty;

  constructor() { }

  initAnalytics(): void {
    const analyticsAccepted = window.localStorage.getItem('analytics');

    //console.log('init analytics')
    if (analyticsAccepted === 'false') {
      return
    }
    console.log('init analytics active')

    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id='+this.gtagProperty;
    document.head.appendChild(scriptElement);

    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '`+ this.gtagProperty +`',{ 'anonymize_ip': true });
      `;
    document.head.appendChild(gaScript);
    window.localStorage.setItem('analytics', 'true');
  }

  enableAnalytics(): void {
    const analyticsAccepted = window.localStorage.getItem('analytics');
    if (analyticsAccepted === 'false') {
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id='+this.gtagProperty;
      document.head.appendChild(scriptElement);

      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '`+ this.gtagProperty +`',{ 'anonymize_ip': true });
      `;
      document.head.appendChild(gaScript);
    }
    window.localStorage.setItem('analytics', 'true');
  }

  disableAnalytics(): void {
    window.localStorage.setItem('analytics', 'false');
    window.location.reload();
  }

}