import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(public authService: AuthenticationService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

    let envDetails = route.data.enableAuthGuard as any;
    console.log(envDetails[environment.name])
    console.log(envDetails)
    if(envDetails[environment.name]){
      if (this.authService.isLoggedIn !== true) {
        window.alert('Access Denied, Login is Required to Access This Page!');
        this.router.navigate(['sign-in']);
      }
      return true;

    } else {
      return true;
    }
  }
}