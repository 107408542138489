import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, doc, getDoc, query, where } from '@angular/fire/firestore';
import { map, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: Firestore) { }

  async getPOI(city: string, chapter: number) {
    const docID = `${city.toLowerCase()}${chapter}`;
    const docRef = doc(this.firestore, 'POIs', docID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return undefined;
    }
  }

  async getQuestion(docRef: any) {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return undefined;
    }
  }

  getTotalChapters(city: string) {
    const collectionRef = collection(this.firestore, 'POIs');
    const q = query(
      collectionRef,
      where('city', '==', city)
    );

    return collectionData(q).pipe(
      take(1),
      map(pois => pois.length));
  }

  async getSettings(city: string) {
    const docRef = doc(this.firestore, 'settings', city.toLowerCase());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return undefined;
    }
  }
}
