<ion-app>
  <ion-split-pane disabled="true" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" swipe-gesture="false">
      <ion-header>
        <ion-toolbar color="light" class="header">
          <ion-buttons slot="start" style="width: 48px; height: 48px;">
            <ion-back-button icon="chevron-back-sharp" style="width: 48px;"></ion-back-button>
          </ion-buttons>
          <ion-title>
            <ion-img src="../../../assets/img/LOGO.png" style="width: 150px; margin: auto;" alt="FERRAGAMO"></ion-img>
          </ion-title>
          <ion-buttons slot="end">
            <ion-menu-button>
              <ion-img src="../../../assets/icons/Close.svg" style="width: 20px;"></ion-img>
            </ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list" lines="full">
          <ion-menu-toggle>
            <ng-container *ngFor="let p of mainPages; let i = index"  >

              <ion-item *ngIf="!p.disabled" routerDirection="forward" [routerLink]="[p.url]"
              detail="false" [ngClass]="i === 0 ? 'main-page' : 'other-page'">
              <ion-label>{{ p.title }}</ion-label>
              <ion-icon name="chevron-forward-sharp" style="width: 30px; height: 65px;"></ion-icon>
            </ion-item>
            </ng-container>
            
            <a href="{{ 'sideMenu.accessibilityUrl' | translate }}" target="_blank" style="text-decoration: none;">
              <ion-item class="other-page">
                <ion-label>
                  {{ 'sideMenu.accessibility' | translate }}
                </ion-label>
                <ion-icon name="chevron-forward-sharp" style="width: 30px; height: 65px;"></ion-icon>
              </ion-item>
            </a>

            <ion-item class="other-page">
              <ion-select [label]="'sideMenu.language' | translate" [(ngModel)]="selectedLanguage" (ionChange)="changeLanguage()"
                [placeholder]="'Seleziona lingua'">
                <ion-select-option value="en">{{ 'sideMenu.languages.english' | translate }}</ion-select-option>
                <ion-select-option value="it">{{ 'sideMenu.languages.italian' | translate }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content">
    </ion-router-outlet>
  </ion-split-pane>

  <app-cookie-banner></app-cookie-banner>
</ion-app>