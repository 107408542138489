export const environment = {
  name:'staging',
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDpigAucTLAivs85ffJogQ4KZh038kpgM0",
    authDomain: "walkingstories-ferragamo-test.firebaseapp.com",
    projectId: "walkingstories-ferragamo-test",
    storageBucket: "walkingstories-ferragamo-test.appspot.com",
    messagingSenderId: "619066477099",
    appId: "1:619066477099:web:5a1a93307ad9798362b86e"
  },
  encryptionKey: 'Ferragamo@2024!',
  gaProperty: 'UA-10076382-1'
};
