import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule]
})
export class CookieBannerComponent  implements OnInit {
  displayBanner: boolean = true;

  constructor(private router:Router) { }

  ngOnInit() {
    const cookiesAccepted = window.localStorage.getItem('cookies');

    if (cookiesAccepted === 'true') {
      this.displayBanner = false;
    }
  }

  closeBanner() {
    window.localStorage.setItem('cookies', 'true');
    this.displayBanner = false;
  }

  acceptCookies() {
    this.router.navigate(['terms','cookies'])
  }
}
